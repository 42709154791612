
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        















































































































































































@import '@/styles/_colors';

.user {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: bp(s)) {
    flex-direction: row;
  }
}

.user__image {
  align-self: flex-start;
  width: span(12);
  margin-bottom: 0.5rem;

  padding: 1rem;
  background: white;
  box-shadow: 0 2px 3px rgba(black, 0.1);

  @media screen and (min-width: bp(m)) {
    width: span(3, 0, span(8));
  }

  @media screen and (min-width: bp(l)) {
    width: span(3, 0, span(6));
  }

  &-size {
    width: 100%;
  }
}

.user__info-btn {
  margin-top: 1rem;
}

.user__info {
  width: span(12);

  margin-left: span(0, 1);
  padding: 1rem;
  background: white;
  box-shadow: 0 2px 3px rgba(black, 0.1);

  @media screen and (min-width: bp(m)) {
    width: span(6, 0, span(8));
    margin-left: span(0, 1, span(8));
  }

  @media screen and (min-width: bp(l)) {
    width: span(4, 0, span(6));
    margin-left: span(0, 1, span(6));
  }

  @media screen and (min-width: bp(xl)) {
    width: span(4, 0, span(6));
    margin-left: span(0, 1, span(6));
  }
}

.product {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  color: var(--color-text);
  text-decoration: none;
  border: 1px solid $color-grey-100;

  &:hover {
    background: rgba($color-grey-500, 0.1);
  }
}

.product__icon {
  margin-right: 0.5rem;
}

.product__parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0.25rem;
  color: $color-grey-600;
  font-size: 0.85rem;
}

.product__name {
  font-weight: 500;
}

.grid-system {
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
  margin: 1rem 0 2rem;
}
